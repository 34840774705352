#call-management-container{
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
  align-items: stretch;
  align-content: stretch;
}

#dial-button{
  background-color: #16ab39;
  flex: 1;
  color: white;
  height: 40px;
  padding: 10px;
}

#transfer-button {
  background-color: #f26202;
  flex: 1;
  color: white;
  height: 40px;
  padding: 10px;
}

#transfer-to-bvl-button {
  background-color: #3488c0;
  flex: 1;
  color: white;
  height: 40px;
  padding: 10px;
}

#transfer-to-mini-move-button {
  background-color: #3488c0;
  flex: 1;
  color: white;
  height: 40px;
  padding: 10px;
}

#self-service-transfer-button {
  background-color: #eaae00;
  flex: 1;
  color: white;
  height: 40px;
  padding: 10px;
}

#hangup-button {
  color: #00827c;
  background-color: transparent;
  border: 2px solid;
  width: 150px;
  height: 40px;
  padding: 10px;
  border-radius: 3px;
}

#drop-button {
  color: red;
  background-color: transparent;
  border: 2px solid;
  width: 150px;
  height: 40px;
  padding: 10px;
  border-radius: 3px;
};