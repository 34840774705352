#exception-button{
  color: red;
  background-color: transparent;
  border: 2px solid;
  width: 150px;
  height: 40px;
  padding: 10px;
  border-radius: 3px;
  right: 20px;
  display: block;
  margin: auto;
}

#exception-description{
  width: 100%;
  min-height: 300px;
}