.agent-status .dropdown {
  border: 2px solid rgba(34,36,38,.15) !important;
  font-weight: 600 !important;
  transition:
    color .3s ease-in-out,
    background-color .3s ease-in-out,
    border-color .3s ease-in-out !important;
}

.agent-status .text {
  font-weight: 600 !important;
}

.agent-status .menu {
  border: none !important;
  top: 105% !important;
}

.agent-status > .disabled,
.agent-status > .disabled .icon {
  opacity: 1 !important;
}

.agent-status .disabled,
.agent-status .disabled .icon {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.agent-status .icon {
  border: none !important;
  transition:
    color .3s ease-in-out,
    background-color .3s ease-in-out,
    border-color .3s ease-in-out !important;
}

.agent-status .offline {
  border-color: #ff0000 !important;
  color: #ff0000 !important;
}

.agent-status .online {
  border-color: #030712 !important;
  color: #030712 !important;
}

.agent-status .break,
.agent-status .lunch,
.agent-status .training,
.agent-status .qa_work,
.agent-status .meeting,
.agent-status .admin_work {
  color: #9b632c !important;
  border-color: #9b632c !important;
}

.agent-status .on_inbound_call,
.agent-status .on_local_call,
.agent-status .on_long_distance_call {
  color: #fff !important;
  border-color: #659287FF !important;
  background-color: #659287FF !important;;
}

.ui.disabled.button {
  cursor: not-allowed !important;
}
