#disposition-management-container {
  margin: 20px 0 !important;
  width: 100%;
}

#disposition-dropdown {
  width: 300px;
}

#disp-buttons {
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
}

#quick-dispositions {
  justify-content: space-around;
  margin-bottom: 0px;
}

#submit-button {
  margin: 15px 100px 15px 15px;
}