#edit_lead {
  color: green;
  background-color: transparent;
  border: 2px solid;
  width: 50px;
  height: 40px;
  padding: 10px;
  border-radius: 3px;
  margin: 10%;
}

h5.heading {
  border: none;
  margin: calc(2rem - .14286em) 0 1rem;
  padding: 0;
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  font-weight: 700;
  font-size: 1.28571429em;
  line-height: 1.28571429em;
  text-transform: none;
  color: rgba(0,0,0,.87);
  border-bottom: 1px solid rgba(34,36,38,.15);
  padding-bottom: .21428571rem;
}