.login-form { 
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20vh;
}

.input-bottom {
   margin-bottom: 16px;
}